import 'magnific-popup';

jQuery( document ).ready( function ( $ ) {

	$(window).bind('mousewheel DOMMouseScroll', function(event){
		if (event.originalEvent.wheelDelta > 0 || event.originalEvent.detail < 0) {
			$('#bg-img').removeClass('fade');
		}
		else {
			$('#bg-img').addClass('fade');
		}
	});

	let touchstartY = 0;
	let touchendY = 0;

	const slider = document.getElementById('slider');

	function handleGesture() {
		if (Math.abs(touchendY - touchstartY) > 10){
			if (touchendY < touchstartY){
				$('#bg-img').addClass('fade');
			}
			if (touchendY > touchstartY) {
				$('#bg-img').removeClass('fade');
			}
		}
	}

	slider.addEventListener('touchstart', e => {
	touchstartY = e.changedTouches[0].screenY;
	});

	slider.addEventListener('touchend', e => {
	touchendY = e.changedTouches[0].screenY;
	handleGesture();
	});

	$( '.newsletter form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		$.ajax( {
			url: 'https://subs.sonymusicfans.com/submit',
			method: 'POST',
			data: $( this ).serialize(),
			success: function ( data, textStatus, jqXHR ) {
				$( 'form' ).replaceWith(
					'<div class="form-thanks">Thanks for signing up!</div>'
				);
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );

    $('.apple-btn').magnificPopup({
		items: {
			src: '#apple-popup',
			type: 'inline'
		}
	});
  
	let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function (callback, form_id) {
		let hash;
		let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for (let i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			this.url_vars.push(hash[0]);
			this.url_vars[hash[0]] = hash[1];
			}
		this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
		this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
		this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
		this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
		this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
		if (typeof callback === 'function') {
			callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
		}
		}
	};
	
	/* String state
		*  The status of the pre-save.
		*  Int ae_member_id
		*  The Appreciation Engine member ID. This can be passed to the forms processor.
		*  String spotify_user
		*  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
		*  String deezery_user
		*  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
		*/
	SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
		// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
		if (state === 'success' || state === 'thank-you') {
			jQuery.magnificPopup.open({
				items: {
				src: '#thank-you',
				type: 'inline'
				}
			});
		}
	});
});

document.addEventListener('musickitloaded', function () {
	let AmkPlaylists = ["pl.c5ccd8d408bc44afbc9ba2bd6a731016"];
	let AmkAlbums = [];
	// Create a new SMEAppleMusic object with your parameters
	let sm = new SMEAppleMusic({
	ae: {
		ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
		brand_id: '3443761',
		segment_id: '1157739',
		activities: '{"actions":{"presave":64136},"mailing_list_optins":{"a0S24000009wvN7EAI":64137}}'
	},
	am: {
		dev_token: '',
		save_mode: 'library', 
		custom_playlist_name: '',
		resources: {
		playlists: AmkPlaylists,
		albums: AmkAlbums
		}
	},
	sf: {
		form: '118005',
		default_mailing_list: 'a0S24000009wvN7EAI'
	},
	smf: {
		campaign_id: '118005',
		campaign_key: 'f34dce2c4591310eb354e9a8778ad61f'
	}
	});

	
	document.getElementById('apple_music_form').addEventListener('submit', function (e) {
		e.preventDefault();
		// Grab the email address value
		const email = document.getElementById('apple_music_email_address').value;
		// Pass the email address and opt in status
		// This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
		sm.doActions(email, true).then(function (res) {
		// Complete - handle your logic here
			jQuery.magnificPopup.open({
				items: {
				src: '#thank-you',
				type: 'inline'
				}
			});
		}).catch(function (err) {
		// Error - handle your logic here
		});
	});
});